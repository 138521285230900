import { Link } from 'react-router-dom';
import paypal from '../../assets/paypal.png';
import mastercard from '../../assets/mastercard.webp';
import visa from '../../assets/visa.png';
import bitcoin from '../../assets/payment-bitcoin.png';
import usdt from '../../assets/payment-usdt.png';

const Footer = () => {
  return (
    <footer className='footer'>
      <div className='grid'>
        <div className='item'>
          <p>
            Take your crypto to the next level with WealthBridge Investments.
          </p>
          <div className='btns'>
            <button type='button' className='gold'>
              <Link to='/sign-up'>Create account</Link>
            </button>
            <button type='button'>
              <Link to='/sign-in'>Sign in</Link>
            </button>
          </div>
        </div>
        <div className='item'>
          <h4>Features</h4>
          <ul>
            <li>
              <Link to='/services'>Services</Link>
            </li>
            <li>
              <Link to='/guides'>Guides</Link>
            </li>
          </ul>
        </div>
        <div className='item'>
          <h4>Plans</h4>
          <ul>
            <li>
              <Link to='/plans'>Plans</Link>
            </li>
          </ul>
        </div>
        <div className='item'>
          <h4>Learn</h4>
          <ul>
            <li>
              <Link to='/guides'>Crypto Guides</Link>
            </li>
            <li>
              <Link to='/terms-of-use'>Terms of Use</Link>
            </li>
            <li>
              <Link to='/privacy-policy'>Privacy Policy</Link>
            </li>
          </ul>
        </div>
        <div className='item'>
          <h4>About</h4>
          <ul>
            <li>
              <Link to='/about'>About</Link>
            </li>
            <li>
              <Link to='/help-center'>Help Center</Link>
            </li>
          </ul>
        </div>
      </div>
      <div className='payment-methods'>
        <div className='img'>
          <img src={mastercard} alt='mastercard payment' />
        </div>
        <div className='img'>
          <img src={visa} alt='visa payment' />
        </div>
        <div className='img'>
          <img src={paypal} alt='paypal payment' />
        </div>
        <div className='img'>
          <img src={bitcoin} alt='bitcoin payment' />
        </div>
        <div className='img'>
          <img src={usdt} alt='usdt payment' />
        </div>
      </div>
      <div className='copyright'>&copy; 2023 WealthBridge Investments</div>
    </footer>
  );
};

export default Footer;
