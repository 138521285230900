import React, { useEffect } from 'react';
import {
  AdvancedRealTimeChart,
  CryptoCurrencyMarket,
} from 'react-ts-tradingview-widgets';
import { useGlobalContext } from '../../context/context';

const SpotTrading = () => {
  const {
    setIsUser,
    resetPage,
    setUserToken,
    userToken,
    fetchUser,
  } = useGlobalContext();

  useEffect(() => {
    if (window.localStorage.getItem(`userDetails`)) {
      setIsUser(true);
    }
  }, [setIsUser]);

  useEffect(() => {
    resetPage(`Spot Trading`);
    // eslint-disable-next-line
  }, []);

  // GET USER TOKEN FROM LOCAL STORAGE
  useEffect(() => {
    setUserToken(JSON.parse(window.localStorage.getItem(`userToken`)));

    // FETCH USER DETAILS
    if (userToken) {
      fetchUser();
    }
    // eslint-disable-next-line
  }, []);
  return (
    <main className='spot-trading-page'>
      <div className='real-time-chart'>
        <AdvancedRealTimeChart
          theme='dark'
          autosize
          symbol='BTCUSD'
        ></AdvancedRealTimeChart>
      </div>

      <div className='cryptocurrency-market'>
        <CryptoCurrencyMarket
          colorTheme='dark'
          width='100%'
          height={400}
        ></CryptoCurrencyMarket>
      </div>
    </main>
  );
};

export default SpotTrading;
