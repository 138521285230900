import { useGlobalContext } from "../../context/context";
import checkMark from "../../assets/check.svg";

const PasswordChanged = () => {
  const { setShowModal, setForgotPassword } = useGlobalContext();

  return (
    <article className='successful-reset'>
      <div className='modal'>
        <div className='img'>
          <img src={checkMark} alt='success' />
        </div>
        <h2>Password Changed Successfully</h2>
        <button
          className='gold'
          onClick={() => {
            setForgotPassword(false);
            setShowModal(false);
          }}
        >
          Login
        </button>
      </div>
    </article>
  );
};

export default PasswordChanged;
