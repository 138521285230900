import React from 'react';
import { customersActivites } from '../../data/data';

const CustomersActivities = () => {
  const randomItem = Math.floor(Math.random() * customersActivites.length);

  return (
    <div className='customers-activities'>
      <div className='progress'></div>
      <p>{customersActivites[randomItem].message}</p>
    </div>
  );
};

export default CustomersActivities;
