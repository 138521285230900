import React, { useEffect } from 'react';
import { CryptoCurrencyMarket } from 'react-ts-tradingview-widgets';
import { useGlobalContext } from '../../context/context';
const Markets = () => {
  const { setIsUser, resetPage, setUserToken, userToken, fetchUser } =
    useGlobalContext();

  useEffect(() => {
    if (window.localStorage.getItem(`userDetails`)) {
      setIsUser(true);
    }
  }, [setIsUser]);

  useEffect(() => {
    resetPage(`Spot Trading`);
    // eslint-disable-next-line
  }, []);

  // GET USER TOKEN FROM LOCAL STORAGE
  useEffect(() => {
    setUserToken(JSON.parse(window.localStorage.getItem(`userToken`)));

    // FETCH USER DETAILS
    if (userToken) {
      fetchUser();
    }
    // eslint-disable-next-line
  }, []);
  return (
    <main className='markets-page'>
      <div className='cryptocurrency-market'>
        <CryptoCurrencyMarket
          colorTheme='dark'
          width='100%'
          height={600}
        ></CryptoCurrencyMarket>
      </div>
    </main>
  );
};

export default Markets;
